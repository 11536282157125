import { isNilOrEmpty } from 'packages/core'
import config from 'config'

export const smallScreenWidth = '1022px'
export const mobileScreenWidth = '767px'
export const headerHeight = 70
export const subHeaderHeight = 80

export const ITEMS_VIEW = {
    LIST: 'list',
    CARD: 'card',
}

export const getFullImageUrl = (imagePath: any) =>
    !isNilOrEmpty(imagePath) ? `${config.BASE_EID_URL}${imagePath}` : undefined
